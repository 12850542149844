@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'PT Sans', sans-serif;
} */


.parallax-top {
  /* The image used for the top */
  background-image: url('../../../../public/images/digital-web-top.png');

  /* Set height and other background properties */
  height: 500px; 
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}


.parallax-header {
  /* The image used for the top */
  background-image: url('../../../../public/images/digital-web-top.png');

  /* Set height and other background properties */
  height: 100px; 
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-bottom {
  /* The image used for the bottom */
  background-image: url('../../../../public/images/digital-web-bottom.png');

  /* Set height and other background properties */
  height: 500px; 
  background-attachment: fixed;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Add some padding and text styling for content */
.contentParallax {
  padding: 20px;
  height: 500px;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
  text-align: center;
}

/* navigation */
  
  .primary-headerLid {
    padding-top: 3rem;
  }

  .containerLid {
    /* --max-width: 1110px; */
  
    width: min(1110px, 100% - 1rem * 2);
    margin-inline: auto;
  }

  .nav-wrapperLid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .padding-block-900Lid {
    padding-block: 5rem;
  }

  .even-columnsLid {
    display: grid;
    gap: 1rem;
  }

  @media (min-width: 50em) {
    .even-columnsLid {
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }
  }

  :where(.flowLid :not(:first-child)) {
    margin-top: var(0.5rem, 3em);
  }

  /* --fs-primary-heading: var(--fs-800);
  --fw-bold: 700;
  --fs-secondary-heading: var(--fs-700);
  --fw-bold: 700;
  --fs-secondary-heading: var(--fs-700); */

  .fs-primary-headingLid {
    font-size: 2.5rem;
    line-height: 1.1;
  }

  .fw-boldLid {
    font-weight: 700;
  }
    /* button */
  
    .buttonLid {
        display: inline-flex;
        cursor: pointer;
        text-decoration: none;
        border: 0;
        border-radius: 100vmax;
        padding: 1.25em 2.5em;
        font-weight: 700;
        font-size: 300;
        line-height: 1;
        color: hsl(0 0% 100%);
        background-color: hsl(12, 88%, 59%);
        box-shadow: 0 1.125em 1em -1em hsl(12, 60%, 45%);
      }
      
      .buttonLid[data-type="inverted"] {
        background-color:hsl(0 0% 100%);
        color: hsl(12, 88%, 59%);
      }
      
      .buttonLid[data-shadow="none"] {
        box-shadow: none;
      }
      
      .buttonLid:hover,
      .buttonLid:focus-visible {
        background-color: hsl(12, 88%, 75%);
      }
      
        /* cta */
  
  .ctaLid {
    position: relative;
    isolation: isolate;
    text-align: center;
  }
  
  @media (min-width: 50em) {
    .ctaLid {
      text-align: left;
    }
  }
  
  .ctaLid::before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    /* background-image: url("images/bg-tablet-pattern.svg"),
      url("images/bg-tablet-pattern.svg"); */
    background-position: -15rem -5rem, 80rem -42rem;
    background-repeat: no-repeat;
    opacity: 0.1;
  }

  .padding-block-900Lid {
    padding-block: 5rem;
  }

  @media (max-width: 50em) {
    .text-center-sm-onlyLid {
      text-align: center;
    }
  
    .text-center-sm-onlyLid p {
      margin-inline: auto;
    }
  }

  .numbered-itemsLid {
    counter-reset: count;
    width: fit-content;
    margin-inline: auto;
  }

  .numbered-items__titleLid {
    font-size: var(--fs-700);
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    line-height: 1;
  }

  .numbered-itemsLid li {
    counter-increment: count;
  }
  
  .numbered-itemsLid div {
    display: grid;
    position: relative;
    align-items: center;
    column-gap: var(--size-300);
    grid-template-columns: min-content 1fr;
  }

  .fs-600Lid {
    font-size: 1rem;
  }

  .fs-300 {
    font-size: var(--fs-300);
  }
  .fs-400 {
    font-size: var(--fs-400);
  }
  .fs-500 {
    font-size: var(--fs-500);
  }

  .numbered-items__bodyLid {
    font-size: var(--fs-625);
    line-height: 1.5;
    grid-column: 1 / -1;
  }

  @media (min-width: 30em) {
    .numbered-items__bodyLid {
      grid-column: 2 / -1;
    }
  }
  
  .numbered-itemsLid div::before,
  .numbered-itemsLid div::after {
    height: 40px;
  }
  
  .numbered-itemsLid div::before {
    content: "0" counter(count);
    display: flex;
    align-items: center;
    color: var(--clr-neutral-100);
    font-weight: var(--fw-bold);
    /* background-color: var(--clr-accent-400); */
    background-color: var(  --clr-primary-400);
    padding: 0 var(--size-500);
    border-radius: 100vw;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  
  @media (max-width: 30em) {
    .numbered-itemsLid div::after {
      --bg: var(--clr-accent-100);
      content: "";
      display: block;
      z-index: -1;
      border-radius: 100vw 0 0 100vw;
      grid-column: 1 / -1;
      grid-row: 1 / 2;
      box-shadow: 5em 0 0 var(--bg);
      background: var(--bg);
    }
  }

  .text-centerLid {
    text-align: center;
  }
  
  .text-centerLid p {
    margin-inline: auto;
  }

  .slider-contentLid {
    border-radius: 0.25rem;
    padding: 3rem 2rem 2rem;
    background: hsl(0 0% 97%);
  }

  .bg-accent-400Lid {
    /* background-color: hsl(12, 88%, 59%); */
    background-color: #FFF;
  }

  .text-neutral-100Lid {
    color: hsl(0 0% 100%);
  }

  .vertical-align-centerLid {
    align-items: center;
  }

  @media (min-width: 50em) {
    .justify-self-end-mdLid {
      justify-self: end;
    }
  }

    /* footer */
  
    .primary-footerLid {
        color: hsl(0 0% 100%);
      }
      
      .primary-footer-wrapperLid {
        display: grid;
        gap: 3rem;
        grid-template-areas:
          "form"
          "nav"
          "logo-social";
      }
      
      .primary-footer-logo-socialLid {
        grid-area: logo-social;
      
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 3rem;
      }
      
      .primary-footer-navLid {
        grid-area: nav;
      }

      .padding-block-700Lid {
        padding-block: 3rem;
      }

      .bg-neutral-900Lid {
        background-color: hsl(232, 12%, 13%);
      }

      .text-neutral-100Lid {
        color: hsl(0 0% 100%);
      }

      .primary-footer-navLid {
        grid-area: nav;
      }
      
      .footer-navLid {
        columns: 2;
        gap: clamp(0.5rem, 30vw, 5rem);
      }
      
      .footer-navLid a {
        color: hsl(0 0% 100%);
        text-decoration: none;
      }
      
      .footer-navLid a:is(:hover, :focus) {
        color: hsl(12, 88%, 59%);
      }
      
      /* slider */
  
  .slider {
    display: flex;
    gap: var(--size-600);
    list-style: none;
  }
  
  .slider > * {
    position: relative;
    flex: 0 0 auto;
    width: 100%;
  }
  
  @media (min-width: 30em) {
    .slider {
      scroll-padding-inline: 25px;
    }
    .slider > * {
      width: 50%;
    }
  }
  
  @media (min-width: 50em) {
    .slider > * {
      width: 35%;
    }
  }
  
  .slider-content {
    border-radius: var(--size-100);
    padding: var(--size-700) var(--size-600) var(--size-600);
    background: var(--clr-neutral-200);
  }
  
  /* Utility classes */
  
.animbody {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    margin: 0;
    background-color: #f0f0f0;
}

.animOuter-div {
    height: 400px;
    width: 500px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.animInner-div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #e0e0e0;
}

.animHeader,
.animFooter {
    height: 50px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
}

.animMiddle-div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
}

.animMiddle-div img {
    width: 300px;
    height: 250px;
}

/* dep if going with line-awesome */
.hand-image {
  display: none;
  width: 20%;
  position: absolute;
  top: 75%;
  animation: point 2s infinite;
  z-index: 2;
}

.hand-icon {
  display: none;
  width: 20%;
  position: absolute;
  top: 75%;
  animation: point 2s infinite;
  z-index: 2;
}

.custom-alert {
  display: none;
  position: absolute;
  top: 40%;
  z-index: 3;
}

@keyframes point {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-in-out;
}

/* begin How to Steps */

.bodyHowToSteps {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #f0f0f0;
}

.OutherHowToSteps-div {
  height: 400px;
  width: 500px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.InnerHowToSteps-div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #e0e0e0;
}

.HeaderHowToSteps,
.FooterHowToSteps {
  height: 50px;
  /* background-color: #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4em;
}

.MiddleHowToSteps-div {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #001BFF; */
}

.MiddleHowToSteps-div img {
  width: 300px;
  height: 250px;
}
