.button {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 1.1;
    font-weight: 600;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    color: #fff;
    border: 2px solid #fff;
  
    &:after {
      content: attr(data-after);
      font-family: "Line Awesome Free";
      font-weight: 900;
    }
  
    &:before {
      content: attr(data-before);
      font-family: "Line Awesome Free";
      font-weight: 900;
    }
  }

  /* .subscription-subscription > a {
  display: inline !important;
} */
  
  .button.text {
    padding: 1.3rem 2.9rem;
  
    &:after {
      margin-left: 0.5rem;
    }
  
    &:before {
      margin-right: 0.5rem;
    }
  }
  
  .button.text.small {
    padding: 1.2rem 1.8rem;
    border: 1px solid #fff;
    font-weight: 500;
  }
  
  .button.round {
    border-radius: 6px;
  }
  
  .button.icon {
    flex-shrink: 0;
    width: 4.2rem;
    height: 4.2rem;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  
  .button.icon.small {
    width: 2.8rem;
    height: 2.8rem;
    font-size: 1.4rem;
  }
  