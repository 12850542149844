.subscribe {
    color: #fff;
    text-align: center;
    max-width: 50rem;
    margin: 2rem auto 3rem;
  
    & h2 {
      margin-bottom: 5rem;
      max-width: 50rem;
    }
  }
  