.cardWrapper{
    display: flex;
    padding: 1rem;
    box-shadow: 1px 1px 10px rgb(0, 0, 0, .5);
    border-radius: 5px;

    img, p {
        flex-grow: 1;
        align-self: center;
    }

    a {
        width: 30px;
        height: 30px;
        align-self: center;
    }
}

.cardIcon{
    max-width: 75px;
}