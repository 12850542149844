.status {
    flex-basis: 100%;
    margin-bottom: 2rem;
  }
  
  .subscribe {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 0.2rem 0.8rem rgba(36, 36, 36, 0.15);
  
    & input {
      border: none;
      padding: 10px;
      padding-left: 15px;
      flex-grow: 1;
      transition: all 0.3s ease;
  
      &:focus {
        outline: none;
        background: rgb(240, 240, 240);
  
        &::placeholder {
          color: #ccc;
        }
      }
  
      &::placeholder {
        font-size: 1.1rem;
        font-weight: 500;
        font-family: Poppins;
        transition: all 0.3s ease;
      }
    }
  }
  