.cards{
   padding: 0;

   & ul{
    padding-left: 0;
   }
}

.nav-link{
    font-size: 1.5rem;
}

.nav-tabs{
    border:none;
    max-height: 40px;
}

.nav-item > a{
    color: gray;
}

.nav-item > a:hover{
    color: black;
}

.nav-item > a.active{
    box-shadow: 0px -2px 3px rgb(0, 0, 0, 0.2);
    color: black;
}

article.cart{
    max-width: 70rem;
}

@media (min-width: 993px) {
    .cards{
        /*max-width: 70rem;
         padding: 4rem; */
    }
}