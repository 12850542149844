.card {
  box-shadow: 0 0.2rem 0.8rem rgba(40, 40, 40, 0.5);
    border-radius: 8px;
    width: 90%;           /* Sets the width of each card */
    /* Background color of the card */
    background-color: var(--color-primary-blue-variant);
    padding: 2.5em;
    /* Provides some space between the cards */
    /* margin: 1%; */
    margin-left: 2.5em;
  }
  
  .card-img-top {
    border-radius: 8px;
    width: 60%;          /* Ensures the image takes up the full width of the card */
  }
  
  .card-body {
    padding: 15px;        /* Adds padding inside the card body for spacing */
  }

  .card-title {
    font-size:x-large;
    font-weight:500;
    color: #EE22E7;
  }
  
  .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  

/* begin How to Steps */

.bodyHowToSteps {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #f0f0f0;
  }
  
  .OutherHowToSteps-div {
    height: 400px;
    width: 500px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .InnerHowToSteps-div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #e0e0e0;
  }
  
  .HeaderHowToSteps,
  .FooterHowToSteps {
    color: #EE22E7;
    height: 50px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    /* background-color: #ccc; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.2em;
    font-weight: 700;
  }
  
  .MiddleHowToSteps-div {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #001BFF; */
    background-color: transparent;
  }
  
  .MiddleHowToSteps-div img {
    width: 300px;
    height: 250px;
  }

  .numbered-itemsLid {
    counter-reset: count;
    width: fit-content;
    margin-inline: auto;
  }

  .numbered-items__titleLid {
    color: var(--color-primary-purple-variant);
    font-size: var(--fs-700);
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    line-height: 1;
  }

  .numbered-itemsLid li {
    counter-increment: count;
  }
  
  .numbered-itemsLid div {
    display: grid;
    position: relative;
    align-items: center;
    column-gap: var(--size-300);
    grid-template-columns: min-content 1fr;
  }

  .numbered-itemsLidTiny {
    counter-reset: count;
    width: fit-content;
    margin-inline: auto;
  }  

  .numbered-items__titleLidTiny {
    font-size: var(--fs-600);
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    line-height: 1;
  }  

  .numbered-itemsLidTiny li {
    counter-increment: count;
  }
  
  .numbered-itemsLidTiny div {
    display: grid;
    position: relative;
    align-items: center;
    column-gap: var(--size-300);
    grid-template-columns: min-content 1fr;
  }  

  .fs-600Lid {
    font-size: 1rem;
  }

  .fs-300 {
    font-size: var(--fs-300);
  }
  .fs-400 {
    font-size: var(--fs-400);
  }
  .fs-500 {
    font-size: var(--fs-500);
  }

  .numbered-items__bodyLid {
    margin-top: 1em;
    font-size: var(--fs-625);
    line-height: 1.5;
    grid-column: 1 / -1;
  }

  @media (min-width: 30em) {
    .numbered-items__bodyLid {
      grid-column: 2 / -1;
    }
  }
  
  .numbered-itemsLid div::before,
  .numbered-itemsLid div::after {
    height: 40px;
  }

  .numbered-itemsLidTiny div::before,
  .numbered-itemsLidTiny div::after {
    height: 20px;
  }  
  
  .numbered-itemsLid div::before {
    content: "0" counter(count);
    display: flex;
    align-items: center;
    /* color: var(--clr-neutral-100); */
    font-weight: var(--fw-bold);
    /* background-color: var(--clr-accent-400); */
    /* background-color: var(  --clr-primary-400); */
    background-color: #EE22E7;
    padding: 0 var(--size-500);
    border-radius: 100vw;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .numbered-itemsLidTiny div::before {
    content: "0" counter(count);
    display: flex;
    align-items: center;
    /* color: var(--clr-neutral-100); */
    font-weight: var(--fw-bold);
    /* background-color: var(--clr-accent-400); */
    /* background-color: var(  --clr-primary-400); */
    background-color: #EE22E7;
    padding: 5px 5px;
    border-radius: 100vw;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  
  /* @media (max-width: 30em) {
    .numbered-itemsLid div::after {
      --bg: var(--clr-accent-100);
      content: "";
      display: block;
      z-index: -1;
      border-radius: 100vw 0 0 100vw;
      grid-column: 1 / -1;
      grid-row: 1 / 2;
      box-shadow: 5em 0 0 var(--bg);
      background: var(--bg);
    }
  } */

  /* Add media query for mobile view */
@media (max-width: 400px) {
  .card {
    width: 100%; /* Make cards take full width on mobile */
    margin: 0 0 1rem 0; /* Adjust margin for mobile view */
  }
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
}

.skeleton-title {
  width: 60%;
  height: 20px;
}

.skeleton-text {
  width: 100%;
  height: 14px;
}

.skeleton-button {
  width: 30%;
  height: 20px;
}

@keyframes loading {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.skeleton {
  background: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
  background-size: 200px 100%;
  animation: loading 1.5s infinite;
}

.wrapperNoOffer {
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  /* for dev */
  /* border: 1px solid #ccc; */
  box-sizing: border-box;
}
.contentNoOffer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contentNoOffer i {
  font-size: 48px;
  margin-bottom: 10px;
}
.contentNoOffer p {
  font-size: 24px;
  margin: 0;
}
.button-wrapper-no-offer {
  margin-top: 20px;
  /* display: flex; */
  justify-content: flex-start;
  width: 100%;
}

span > p {
  display:flex;
  font-size: 2rem;
  justify-content: center;
  /* text-align: center; */
  text-transform: uppercase;
}

.figure {
  background-color: #EE22E7;
  width: auto;
  height: 6px;
  bottom: 0;
  right: 0;
  display: block;
  transition: all ease 0.5s;
  font-weight: 500;
}

.auth-wrapper-offer-child {
  color: var(--color-primary-purple-variant);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  /* z-index: 50000; */
  padding-top:45px;
  padding-bottom:50px;
  flex-grow: 1;
}

.auth-inner-offer-child {
  
  /* width: 450px; */
  /* max-width: 450px; */
  /* margin: 2em; */
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 22px 30px 45px 25px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper-offer-child .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper-offer-child h3 {
  text-align: left;
  margin: 0;
  line-height: 1;
  padding-bottom: 10px;
}

.footer-container-main {
  z-index: 5000;
}