@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  /* box-sizing: border-box;
  margin: 0;
  padding: 0; */
  /* font-family: 'Poppins', 'PT Sans', sans-serif !important; */
}

.headerBackground{
  background:white;
  /* margin-top: -100px; */
  & .inner{
    width: 100vw;
    max-width: 100vw;
  }
}

.video {
    position: relative;
    margin-top: 100px;
    width: 100%;
    height: 400px;
    /* height: 50vh; */
    background: #7357C0;
    /* background: url('../../../assets/images/orangeHeroGiftCards.jpg') center center/cover no-repeat; */
    /* background: url('../../../assets/images/iPhoneParty.png') center right/contain no-repeat; */
    /* z-index: 1; */
    &:after {
      content: "";
      /* position: absolute; */
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      /* background: url('../../../assets/images/bg/home4.jpg') center center/cover no-repeat; */
    }
  
    & video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  
  .textWrapperHeroSection {
    font-family: 'Poppins' !important;
    /* margin-top: 100px; */
    height: 400px;
    display: flex;
    flex-wrap: wrap;
  }

  .columnHero {
    justify-content: center;
    display: flex;
    /* background-color: #ffff00; */
    flex: 1; /* Grow equally */
    padding: 20px; /* Spacing inside the columns */
    box-sizing: border-box; /* Include padding and border in the element's width and height */
    flex-direction: column;
    align-items: center; 
    
    &>div {
      display: flex;
      justify-content: center;
      /* margin-bottom: 4rem; */
      width: 100%;
  
      &>span {
        color: #fff;
        font-size: 8rem;
        margin: 0 1.5rem;
        font-weight: 200;
      }
    }
  
    & button {
      position: relative;
      width: 13rem;
      height: 13rem;
      border-radius: 50%;
      border: 3px solid;
      cursor: pointer;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease;
  
      &:hover {
        border-color: #EE22E7 !important;
  
        & .bar {
          background-color: #EE22E7 !important;
        }
      }
  
      & .icon_wrapper {
        position: relative;
        transform: translate(-2rem, -2.6rem);
      }
  
      &.pause {
        & .bar_1 {
          transform: translateX(11px) translateY(0px) rotate(0deg);
        }
  
        & .bar_2 {
          transform: translateX(26px) translateY(0px) rotate(0deg);
        }
      }
  
      &.play {
        & .bar_1 {
          transform: translateX(20px) translateY(-8px) rotate(-55deg);
        }
  
        & .bar_2 {
          transform: translateX(20px) translateY(8px) rotate(-125deg);
        }
      }
    }
  
    .bar {
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 0;
      width: 3px;
      height: 30px;
      border-radius: 3px;
      transform-origin: center;
      transition: transform 0.4s ease, background 0.5s ease;
    }
  }

  .columnHero > h1 {
    font-size: 4.5rem;
    line-height: 1.4;
    padding-bottom: 20px;
  }
  
  .columnHero > span {
    font-size: 2.0rem;
    line-height: 1.4;
    padding-bottom: 70px;
  }

  .columnCenterHero {
    flex: .2;
  }

  .thirdColumn {
    background: url('../../../assets/images/bc_ai/iPhoneParty.png') center right/contain no-repeat;
  }

  .textContainer {
    height: 400px;
    background-color: #CCCC00;
    position: absolute;
    top: 25%;
  }

  .fullWidthRow {
    justify-content: center;
    display: flex;
    text-align: center;
    width: 100%; /* Span the full width */
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;

  }
  .fullWidthRow > p {
    padding: 5px;
  }

  .intro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  
    &>div {
      display: flex;
      justify-content: center;
      /* margin-bottom: 4rem; */
      width: 100%;
  
      &>span {
        color: #fff;
        font-size: 8rem;
        margin: 0 1.5rem;
        font-weight: 200;
         font-family: 'Poppins', 'PT Sans', sans-serif !important;
      }
    }

    & button {
      position: relative;
      width: 13rem;
      height: 13rem;
      border-radius: 50%;
      border: 3px solid;
      cursor: pointer;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease;
  
      &:hover {
        border-color: #fff !important;
  
        & .bar {
          background-color: #fff !important;
        }
      }
  
      & .icon_wrapper {
        position: relative;
        transform: translate(-2rem, -2.6rem);
      }
  
      &.pause {
        & .bar_1 {
          transform: translateX(11px) translateY(0px) rotate(0deg);
        }
  
        & .bar_2 {
          transform: translateX(26px) translateY(0px) rotate(0deg);
        }
      }
  
      &.play {
        & .bar_1 {
          transform: translateX(20px) translateY(-8px) rotate(-55deg);
        }
  
        & .bar_2 {
          transform: translateX(20px) translateY(8px) rotate(-125deg);
        }
      }
    }
  
    .bar {
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 0;
      width: 3px;
      height: 30px;
      border-radius: 3px;
      transform-origin: center;
      transition: transform 0.4s ease, background 0.5s ease;
    }
  }

  .heroFontFamily{
    color: #8705EF;
    font-family: 'Poppins', 'PT Sans', sans-serif !important;
  }
  
  @media (max-width: 768px) {
    .intro>div {
      flex-wrap: wrap;
    }
  }
  
  @media (max-width: 768px) {
    .intro>div>span {
      font-size: 5rem;
    }
  
    .intro button {
      width: 9rem;
      height: 9rem;
    }

    .intro a {
      width: 9rem;
    }

    .textWrapperHeroSection{
      height: auto;
    }

    .textContainerHeroSection{
      max-width: fit-content;
      flex:none;
      z-index: 1000;
    }

    /* .columnHero {
       flex: none;
    } */
    .columnCenterHero > button {
      /* flex: 1; */
      z-index: 1000;
      border-color: rgb(255, 183, 3);
      position: absolute;
      top: 28%;
      left: 36%;
    }
  
    .thirdColumn {
      /* flex:1; */
      height: 300px;
      width: 376px;
      position: absolute;
      top: 29%;
      z-index: 0;
      /* left: 33%; */
      right:0;
    }

    .fullWidthRow {
      z-index: 1000;
      position: relative;
    }
  }

  @media (max-width: 768px) {
    .thirdColumn {
      width: 100vw;
      left: 0;
      opacity: .6;
    }
  }

  /* for use by HomeImage */
.homeImage {
  /*background: url('/images/img-home.jpg') center center/cover no-repeat;*/
  background: url('../../../assets/images/bg/home4.jpg') center center/cover no-repeat;
}

/* end for use by HomeImage */