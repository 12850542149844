.logo {
    display: flex;
    align-items: center;
    /* max-width: 50%; */
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: 2px;
    border-bottom: 1px solid;
    padding-bottom: 5px;
  
    & i {
      font-size: 2.2rem;
      margin-right: 0.5rem;
    }
  
    & b {
      font-weight: 500;
    }
  }
  
  .logo.dark {
    /* color: #fff; */
    color: transparent;
  }
  .logo.light {
    color: transparent;
  }
  