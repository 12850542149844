.wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .spinner {
    width: 80px;
    height: 80px;
    position: relative;
    //margin: auto;
  }
  
  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgb(240, 240, 240);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
  
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }
  
  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  
  @-webkit-keyframes sk-bounce {
  
    0%,
    100% {
      -webkit-transform: scale(0);
    }
  
    50% {
      -webkit-transform: scale(1);
    }
  }
  
  @keyframes sk-bounce {
  
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
  
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }