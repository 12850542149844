.wrapper {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    transition: all 1s ease;
    padding: 0;
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 10px;
  }
  
/* CHANGES THE INNER PART OF THE HEADER BUT NOT THE OUTER TO CHANGE OUTER GO TO LAYOUT.module */ 
.header {
    height: 100px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-image: url(../../../assets/kp/bg-tablet-pattern.svg); */
    background: var(--color-primary-blue-variant);
    /* //background-image: url(../../../assets/placeholders/bg-texture.png); */
  }

  .home{
    max-width: 300px;
  }

  .user{
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
.sticky {
    /* background: rgb(40, 40, 50); */
    /* background: rgb(5, 145,215); */
    background: var(--color-primary-blue-variant);
    position: fixed;
    top: 0;
  }
  
.hidden {
    position: fixed;
    top: -100%;
  }
  
.menu {
    display: flex;
  
    & > li {
      position: relative;
  
      & > a,
      & span > a {
        display: block;
        color: #fff;
        /* color: #000000; */
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.4;
        padding: 4rem 2rem;
        transition: all 0.3s ease;
        text-decoration: none;
      }
  
      & > span > a:after {
        content: "\f107";
        font-family: "Line Awesome Free";
        font-weight: 700;
        margin-left: 7px;
      }
  
      &:hover ul {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        pointer-events: visible;
      }
  
      & ul {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 25rem;
        background-color: #fff;
        box-shadow: 0 0.2rem 0.8rem rgba(40, 40, 50, 0.1);
  
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transform: translateY(50px);
        transition: all 0.5s ease;
  
        & li:not(:last-of-type) {
          border-bottom: 1px solid #ddd;
        }
  
        & a {
          color: #000;
          font-size: 1rem;
          letter-spacing: 1px;
          font-weight: 600;
          display: block;
          padding: 2rem;
          transition: all 0.3s ease;
  
          &:hover {
            background: #ddd;
          }
        }
      }
    }
  }
  
.btn_mobile {
    display: none;
    padding: 30px 0;
  }

.loginlogout > a:hover {
  color: #8705EF !important;
}
  
@media (max-width: 992px) {
    .menu {
      display: none;
    }
  
    .btn_desktop {
      display: none;
    }
  
    .btn_mobile {
      display: flex;
    }
  }
  