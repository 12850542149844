.card {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  /* flex-direction: column; */
  flex-direction: row;
  height: 100%;
  border: 1px solid #eee;
  align-items: flex-start;
  padding: 1rem;
  width: 100%;

  /* //background: var(--color-light); */
  & a {
    color: #000;
  }

  & h3 {
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 1.5rem;
    width: 100%;
  }

  & p {
    margin: 1.5rem 0;
    width: 100%;
  }

  & .btn {
    align-self: flex-end;
    margin-top: auto;
  }

  & .iColor {
    color:#000000;
  }

  & .extra {
    display: flex;

    & a {
      margin-right: 2rem;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      color: #ccc;
      transition: all 0.3s ease;

      &:hover {
        color: #aaa;
      }
    }

    & i {
      font-size: 2.5rem;
      color: #000000;
      margin-right: 4px;
    }
  }

  & .title{
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    max-width: -webkit-fill-available;
    width: 92%;
  }

  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    border: 0px gray solid;
    box-shadow: 0px 0px 3px;
    margin:5px;
  }
}

@media (max-width: 576px) {
  .card {
    & article {
      padding: 15px;
    }

    & p {
      margin: 10px 0;
    }
  }
}
