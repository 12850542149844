.spinner{
    position: fixed;
    top:50%;
    margin-top: -40px;
    left:50%;
    margin-left: -40px;
    z-index: 70001;
}

.spinnerBackdrop{
    z-index: 70000;
}