.wrapper {
    /* background-color: aquamarine; */
    display: flex;
    max-width: 1140px;
    margin: 0 auto;
    /* margin-top: 100px; */
    min-height: 500px;
    align-items: flex-start;
    & article {
      width: 100%;
    }
  }

  .outerWrapper{
    padding:50px 0 50px 0;
  }
  
  .sidebar {
    /* background-color: blueviolet; */
    display: none;
    flex-basis: 25%;
    flex-shrink: 0;
    flex-direction: column;
  }
  
  .with_sidebar .sidebar {
    display: flex;
  }
  
  .with_sidebar.left .sidebar {
    margin-right: 30px;
  }
  
  .with_sidebar.right .sidebar {
    margin-left: 30px;
    order: 1;
  }
  
  .grid {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
    justify-content: center;
  
    & > * {
      flex-basis: 20%;/* flex-basis: calc(33.333333% - 30px); */
      margin: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .list {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
  
    & > * {
      flex-basis: 100%;
      margin: 15px;
    }
  }
  
  .with_sidebar .grid > * {
    flex-basis: 20%;/* flex-basis: calc(50% - 30px); */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* change up to reflect as left side navigation */
  .categories {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  
    & h3 {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 600;
      align-self: flex-start;
      margin-bottom: 0;
      padding: 0.6rem 1.4rem;
      letter-spacing: 0;
    }
  
    & ul {
      border: 1px solid rgb(240, 240, 240);
    }
  
    & li {
      transition: all 0.5s ease;
    }
  
    & a {
      font-size: 14px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;
      font-weight: 600;
      border-bottom: 1px solid rgb(240, 240, 240);
      color: #000;
    }
  
    & i {
      margin-right: 2rem;
      font-size: 1.3rem;
      color: #ccc;
    }
  }

  .secHeader {
    /* background-color: #FF00FF; */
    padding-top: 0px;
    padding-bottom: 20px;

    & h1 {
      margin-top: -50px;
      font-size: xx-large;
    }
  }

  @media (max-width: 1200px) {
    .wrapper {
      max-width: 932px;
    }
  }
  
  @media (max-width: 992px) {
    .wrapper {
      max-width: 708px;
      flex-direction: column;
    }
  
    .with_sidebar .sidebar {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      order: 1;
      margin-top: 100px;
    }
  
    .grid > * {
      flex-basis: 20%;/* flex-basis: calc(50% - 30px); */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  @media (max-width: 768px) {
    .wrapper {
      max-width: 516px;
    }
  
    .grid,
    .list {
      margin: 15px 0;
    }
  
    .grid > *,
    .list > * {
      flex-basis: 100%;
      margin: 15px 0;
    }
  
    .with_sidebar .grid > * {
      flex-basis: 100%;
      margin: 15px 0;
    }
  }
  
  @media (max-width: 576px) {
    .wrapper {
      max-width: 100%;
      margin: 0 30px;
    }
  
    .with_sidebar .sidebar {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  
    .with_sidebar.left .sidebar {
      margin-right: 0;
    }
  
    .with_sidebar.right .sidebar {
      margin-left: 0;
    }

    .categories a {
      padding: 15px 20px;
    }    
  }
  

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    article {
      width: 100%;
    }
  
    header {
      height: 250px;
    }
  }
  