.services {
    width: 100%;
  
    & p {
      color: inherit;
    }
  }
  
  .footer {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 3rem !important;
    color: #fff;
  
    & span {
      font-size: 1.8rem;
      font-weight: 300;
      color: inherit;
  
      & b {
        font-weight: 500;
        margin-right: 1rem;
        color: inherit;
        border-bottom: 1px solid #fff;
      }
    }
  }
  
  @media (max-width: 576px) {
    .footer {
      margin-top: 1rem !important;
      flex-direction: column;
  
      & span {
        &:first-of-type {
          margin-bottom: 1rem;
        }
  
        & b {
          margin-right: 0;
        }
      }
    }
  }