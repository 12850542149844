.wrappert {
  margin: auto;
  width: 50%;
  /* border: 3px solid green; */
  padding: 10px;
  text-align: center;
  }
  
.center {
    margin: auto;
    width: 50%;
    border: 3px solid green;
    padding: 10px;
    text-align: center;
  }

.inputEmail {
  margin-bottom: 1em;
  padding: 6px;
}

.inputPassword {

  padding: 6px;
}

.btnSubmit{
  margin-bottom: 1.8em;
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.emptyDivHeight {
  height: 400px;
}

.center {
  margin: auto;
  width: 50%;
  /* border: 5px solid #FFFF00; */
  /* display: flex; */
  /* height: 150px; */
  align-items: center;
  }