/* From KP */
:root {
  --clr-accent-500: hsl(12, 60%, 45%);
  --clr-accent-400: hsl(12, 88%, 59%);
  --clr-accent-300: hsl(12, 88%, 75%);
  --clr-accent-100: hsl(13, 100%, 96%);

  --clr-primary-400: hsl(228, 39%, 23%);

  --clr-neutral-900: hsl(232, 12%, 13%);
  --clr-neutral-200: hsl(0 0% 97%);
  --clr-neutral-100: hsl(0 0% 100%);

  --ff-primary: "Be Vietnam Pro", sans-serif;

  --ff-body: var(--ff-primary);
  --ff-heading: var(--ff-primary);

  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-bold: 700;

  --fs-300: 0.8125rem;
  --fs-400: 0.875rem;
  --fs-500: 0.9375rem;
  --fs-600: 1rem;
  --fs-625: 1.25rem;
  --fs-650: 1.5rem;
  --fs-700: 1.875rem;
  --fs-800: 2.5rem;
  --fs-900: 3.5rem;

  --fs-body: var(--fs-400);
  --fs-primary-heading: var(--fs-800);
  --fs-secondary-heading: var(--fs-700);
  --fs-nav: var(--fs-500);
  --fs-button: var(--fs-300);

  --size-100: 0.25rem;
  --size-200: 0.5rem;
  --size-300: 0.75rem;
  --size-400: 1rem;
  --size-500: 1.5rem;
  --size-600: 2rem;
  --size-700: 3rem;
  --size-800: 4rem;
  --size-900: 5rem;

  --op-05: .5;
}

body{
  /* padding-top: 100px; */
  overflow-x:clip;
}

/* END from KP */

/* from KP */

.app-content{
  flex-grow:1;
  /* padding: 100px 0 100px 0; */
  top: 100px;
  position: relative;

  .backdrop{
    margin-top: -100px;
  }
}

.footerKPContainer {
  --max-width: 1110px;
  --container-padding: 1rem;

  width: min(var(--max-width), 100% - (var(--container-padding) * 2));
  margin-inline: auto;
}
/* Utility KP classes */

.text-primary-400 {
  color: var(--clr-primary-400);
}
.text-accent-400 {
  color: var(--clr-accent-400);
}
.text-accent-100 {
  color: var(--clr-accent-100);
}
.text-neutral-100 {
  color: var(--clr-neutral-100);
}
.text-neutral-900 {
  color: var(--clr-neutral-900);
}

.bg-primary-400 {
  background-color: var(--clr-primary-400);
}
.bg-accent-400 {
  background-color: var(--clr-accent-400);
}
.bg-accent-100 {
  background-color: var(--clr-accent-100);
}
.bg-neutral-100 {
  background-color: var(--clr-neutral-100);
}
.bg-neutral-900 {
  background-color: var(--clr-neutral-900);
}

.fw-bold {
  font-weight: var(--fw-bold);
}
.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}
.fw-regular {
  font-weight: var(--fw-regular);
}

.fs-primary-heading {
  font-size: var(--fs-primary-heading);
}
.fs-secondary-heading {
  font-size: var(--fs-secondary-heading);
}

.fs-300 {
  font-size: var(--fs-300);
}
.fs-400 {
  font-size: var(--fs-400);
}
.fs-500 {
  font-size: var(--fs-500);
}
.fs-600 {
  font-size: var(--fs-600);
}
/* END KP Utility classes */

/* KP footer */

.primaryFooter {
  --logo-color: var(--clr-neutral-100);
}

.primaryFooterWrapper {
  display: grid;
  gap: var(--size-700);
  grid-template-areas:
    "form"
    "nav"
    "logo-social";
}

.primaryFooterLogoSocial {
  grid-area: logo-social;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: var(--size-700);
}

.primaryFooterNav {
  grid-area: nav;
}

.primaryFooterForm {
  grid-area: form;

  display: grid;
  align-content: space-between;
}

.primaryFooter form {
  display: flex;
  gap: var(--size-200);
}

input:where([type="text"], [type="email"], ) {
  border-radius: 100vw;
  border: 0;
  padding: var(--size-300) var(--size-400);
}

@media (min-width: 50em) {
  .primaryFooterWrapper {
    grid-template-areas: "logo-social nav form";
  }

  .primaryFooterLogoSocial {
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--size-700);
  }
}

@media (max-width: 50em) {
  .primaryFooterWrapper > * {
    margin-inline: auto;
  }
}

/* KP footer navigation */

.footerNav {
  columns: 2;
  gap: clamp(var(--size-200), 30vw, var(--size-900));
}

.footerNav a {
  color: var(--clr-neutral-100);
  text-decoration: none;
}

.footerNav a:is(:hover, :focus) {
  color: var(--clr-accent-400);
}

/* END KP footer

/* more footer for the subscribe input */
.contactInputEmail[type="email"] {
  width: 350px;
  border: 1px solid #ccc;
  background: #FFF;
  margin: 0 0 5px;
  padding: 10px;
  margin-top: 12px;
  border-radius: 6px;
}

.contactInputEmail[type="email"],
.contactButton[type="button"] {
  font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
}

input.contactInputEmail:focus {
  outline: none;
  border: 2px solid #007BFF; /* Blue border */
  background-color: #E6F4FF; /* Light blue background */
}

.contactInputEmail[type="email"]:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

/* end custom footer for subscribe input */

/* KP social list */

.socialList {
  display: flex;
  gap: var(--size-600);
}

.socialIcon {
  width: var(--size-600);
  aspect-ratio: 1;
  fill: var(--clr-neutral-100);
}

.socialList a:is(:hover, :focus) .social-icon {
  fill: var(--clr-accent-400);
}
/* END KP social list */

/* KP from 611 */
.textNeutral100 {
  color: var(--clr-neutral-100);
}

/* KP from 630 */
.bgNeutral900 {
  background-color: var(--clr-neutral-900);
}

/* KP from 669 */
.paddingBlock700 {
  padding-block: var(--size-700);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
/* @import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800"); */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'PT Sans', sans-serif;
}

/* from LoginNew.css */
h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Poppins", 'PT Sans', sans-serif;
}
/* deprecate this in future versions
body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}
end deprecate
*/

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  /* z-index: 50000; */
  padding-top:50px;
  padding-bottom:50px;
  flex-grow: 1;
}

.auth-inner {
  /* width: 450px; */
  max-width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 22px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

/* END from LoginNew.css */




.home,
.offers,
.faq,
.products,
.details,
.sign-up {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.offers {
  padding-top: 90px;
  /* background-image: url('/images/cryptobg.jpg'); */
  /* background-image: url('/images/CRYPTOFRON.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

img.rounded-corners {
  border-radius: 10px;
}

.secondaryAboutContent,
.secondaryFaqContent,
.secondarySupportContent {
  font-size: 1.4rem !important;
    * 
    {
      line-height: 1.2;
      font-size: inherit !important;
    }
}



/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"); */

* html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .gradient-bg-welcome {
  background-color:#0f0e13;
  background-image: 
    radial-gradient(at 0% 0%, hsla(253,16%,7%,1) 0, transparent 50%), 
    radial-gradient(at 50% 0%, hsla(225,39%,30%,1) 0, transparent 50%), 
    radial-gradient(at 100% 0%, hsla(339,49%,30%,1) 0, transparent 50%);
} */

.gradient-bg-services {
  background-color:#0f0e13;
  background-image: 
    radial-gradient(at 0% 0%, hsla(253,16%,7%,1) 0, transparent 50%), 
    radial-gradient(at 50% 100%, hsla(225,39%,25%,1) 0, transparent 50%);
}

.gradient-bg-transactions {
  background-color: #0f0e13;
  background-image: 
    radial-gradient(at 0% 100%, hsla(253,16%,7%,1) 0, transparent 50%), 
    radial-gradient(at 50% 0%, hsla(225,39%,25%,1) 0, transparent 50%);
}

.gradient-bg-footer {
  background-color: #0f0e13;
  background-image: 
    radial-gradient(at 0% 100%, hsla(253,16%,7%,1) 0, transparent 53%), 
    radial-gradient(at 50% 150%, hsla(339,49%,30%,1) 0, transparent 50%);
}

.blue-glassmorphism {
  background: rgb(39, 51, 89, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/* white glassmorphism */
.white-glassmorphism {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.eth-card {
  background-color:#a099ff;
  background-image: 
    radial-gradient(at 83% 67%, rgb(152, 231, 156) 0, transparent 58%), 
    radial-gradient(at 67% 20%, hsla(357,94%,71%,1) 0, transparent 59%), 
    radial-gradient(at 88% 35%, hsla(222,81%,65%,1) 0, transparent 50%), 
    radial-gradient(at 31% 91%, hsla(9,61%,61%,1) 0, transparent 52%), 
    radial-gradient(at 27% 71%, hsla(336,91%,65%,1) 0, transparent 49%), 
    radial-gradient(at 74% 89%, hsla(30,98%,65%,1) 0, transparent 51%), 
    radial-gradient(at 53% 75%, hsla(174,94%,68%,1) 0, transparent 45%);
}

.text-gradient {
  background-color: #fff;
  background-image: radial-gradient(at 4% 36%, hsla(0,0%,100%,1) 0, transparent 53%), radial-gradient(at 100% 60%, rgb(0, 0, 0) 0, transparent 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

.centered{
  margin-left: 100px;
  margin-right: 100px;
}

.centeredForm{
  margin-left: 300px;
  margin-right: 300px;
}

.conversionsWalletAddress {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.4;
  border: 1px solid #fff;
  padding: 4rem 8rem;
  letter-spacing: 3px;
  text-align: center;
}

.buttonStyle {
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}

/* following two classes are for home page gift card row */
/* Container for the row of gift cards */
.gift-card-container {
  display: flex; /* Arrange cards horizontally */
  justify-content: space-between; /* Distribute cards evenly with spacing */
  margin-top: 20px !important;
  margin-bottom: 20px; /* Optional spacing below the row */
}

/* Individual gift card styles */
.gift-card {
  overflow: hidden;
  width: 250px; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
  background-color: #fff; /* Adjust background color if desired */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Drop shadow */
  margin: 0 10px; /* Spacing between cards */
  text-align: center; /* Center card content */
  /* padding: 0px; */ /* Adjust padding as needed */
}

.gift-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .gift-card-container {
    flex-direction: column; /* Stack cards vertically */
  }
}

@media (max-width: 768px) {
  .gift-card {
    margin: 10px auto;
    /* Adjust spacing between stacked cards */
    
  }
}


/* END home page gift card row */
/* 

<div class="containerDevDrive">
   <header class="cell cell-1">Header</header>
   <aside class="cell cell-2">Left sidebar</aside>
   <main class="cell cell-3">Main content</main>
   <aside class="cell cell-4">Right sidebar</aside>
   <footer class="cell cell-5">
       <div class="cell cell-6">Footer 1</div>
       <div class="cell cell-7">Footer 2</div>
       <div class="cell cell-8">Footer 3</div>
       <div class="cell cell-9">Footer 4</div>
   </footer>
 </div> 

*/
/* from https://www.developerdrive.com/how-to-create-a-css-grid-step-by-step/ */
.containerDevDrive {
  height: 90vh;
  width: 90vw;
  margin: 2rem auto;
  display: grid;
  grid-template-areas: "header header header"
                       "left main right"
                       "footer footer footer";
  grid-template-rows: 1fr 3fr 1fr;
  grid-template-columns: 1fr 2fr 1fr;
}
.cell-1 {
  background: deepskyblue;
  grid-area: header;
}
.cell-2 {
  background: orangered;
  grid-area: left;
}
.cell-3 {
  background: limegreen;
  grid-area: main;
}
.cell-4 {
  background: gold;
  grid-area: right;
}
.cell-5 {
  background: blueviolet;
  grid-area: footer;
}
.cell {
  color: white;
  font-size: 2.5rem;
  text-align: center;
  padding: 4rem;
}

.cell-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1rem;
}
.cell-5 .cell {
  background-color: coral;
}
/* END developer drive.com */

/* from https://getbootstrap.com/docs/5.2/examples/ */

.icon-list li::before {
  display: block;
  flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  margin-right: .5rem;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") no-repeat center center / 100% auto;
}

      .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }

      .b-example-divider {
        height: 3rem;
        background-color: rgba(0, 0, 0, .1);
        border: solid rgba(0, 0, 0, .15);
        border-width: 1px 0;
        box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
      }

      .b-example-vr {
        flex-shrink: 0;
        width: 1.5rem;
        height: 100vh;
      }

      .bi {
        vertical-align: -.125em;
        fill: currentColor;
      }

      .nav-scroller {
        position: relative;
        z-index: 2;
        height: 2.75rem;
        overflow-y: hidden;
      }

      .nav-scroller .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        margin-top: -1px;
        overflow-x: auto;
        text-align: center;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }

/* end from https://getbootstrap.com/docs/5.2/examples/ */

/* from /scr/components/CSS/Footer.css
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: 'Poppins', 'sans-serif';
} */

.subscription-background{
  background: linear-gradient(90deg, rgb(147, 8, 247) 0%, rgba(171, 14, 227, 0.838) 100%) !important;
  background-size: cover;
}

/* Subscription Component */
.subscription-container {
  /*background-color: #242424;*/
  min-height: 300px;
  /* background: linear-gradient(90deg, rgb(223, 103, 4) 0%, rgb(192, 119, 2) 100%); */
  /* background: linear-gradient(90deg, rgb(147, 8, 247) 0%, rgba(171, 14, 227, 0.838) 100%); */
  /* background-color: rgb(5, 145, 215); */
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-image: url('/src/assets/images/bg/topbar-black.png'); */
  /* background-size: cover; */
  /* background-position: center;
  background-size: cover; */
  /* background-repeat: no-repeat; */

}

.bottomLeft {
  /* background-color: #00FF00; */
  /* background: url('./assets/images/EthAndPhone.png') bottom left/contain no-repeat; */
  width: 33%;
  object-fit: contain;
  /* margin-left: -20%; */
  margin-bottom: -10%;
}

.content-wrapper {
  flex: 1; /* Takes up the remaining space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center align the items if that's what you're going for */
  text-align: center; /* Ensures text within the content wrapper is centered */
}

.subscription-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* margin-bottom: 24px; */
  /* padding: 14px; */
  color: #fff;
  /* color: #000; */
}

.subscription-subscription > p {
  font-family: 'Poppins', 'PT Sans', 'sans-serif';
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif; */
}

.subscription-subscription-heading {
  margin-top: 0px;
  margin-bottom: 34px;
  font-size: var(--fs-700);
}

.subscription-thank-you-wrapper{
  /* background-color: #09f; */

}

.subscription-thank-you {
  /* background-color: #09f; */
  line-height: 1.4;
  padding-left: 6em;
  padding-right: 6em;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: var(--fs-700);
}

.subscription-text-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 80px;

}
.subscription-subscription-text {
  /* margin-top: 20px;
  margin-bottom: 24px; */
  font-size: var(--fs-650);
}

/* End Subscription Component */



.website-rights {
  color: #FFF;
  opacity: 0.7;
  font-size: var(--fs-650);
  /* max-width: 32ch; */
  /* color: #fff; */
  /* color: #000; */
  /* margin-bottom: 16px; */
}

.layout-team{
  background-color: var(--color-primary-purple-variant) !important;
  /* background-color: #ffff00 !important; */
  /* background-color: #44AA00; */
  
  /* height: 700px; */
  width: 100%;
  background-size: 1000px !important;
  /* background-size: cover !important; */
  background-repeat: repeat !important;
  background-position: center !important;

}
/* Begin Privacy Policy */
.privacyContainer {
  /* background-color: #ccc; */
  
}
.privacyContainer .privacyRow {
  /* background-color: #FF0000; */
}

.privacyCol {
  font-size: var(--fs-650);
  p, h1, h2 {
    padding-left: 8%;
    padding-right: 8%;
    font-size: var(--fs-650);
    line-height: 1.8em;
  }
  
}
.privacyCol > h1 {
  font-size: var(--fs-800);
  text-align: center;
  padding-top: 2%;
  padding-bottom: 1%;
}

.privacyCol > h2 {
  font-size: var(--fs-700);
  padding-top: 5px;
  padding-bottom: 14px;
}
.privacyCol > ul {
  padding-left: 10%;
  padding-right: 10%;
}
.privacyCol > ul > li {
  list-style-type: disc;
  font-size: var(--fs-625);
  margin-bottom: 6px;
}
.privacyTable {
  margin: 20px;
  font-size: var(--fs-625);
  line-height: 1.8em;
  padding-left: 8%;
  padding-right: 8%;
}
.privacyContainer th, td {
  vertical-align: top;
}
/* END Privacy Policy */


@media (min-width: 993px){
  .layout-features{
    height:650px;
  }

  /* .footer-container {
    height: 325px;
  } */

   .layout-footer {
    bottom:15px;
    right:0;
    left:0;
  }

}

@media screen and (max-width: 992px){
  .privacyContainer {
    /* background-color: #CCCCCC; */
  }

  .privacyCol {
    /* background-color: #55ccff; */
    font-size: var(--fs-650);
    p, h1, h2 {
      padding-left: 6%;
      padding-right: 6%;
      font-size: var(--fs-650);
      line-height: 1.8em;
    }
  }

  .privacyCol > h1 {
    font-size: var(--fs-800);
    text-align: center;
  }
}

@media screen and (max-width: 820px) {
  /* Privacy Policy */
  .privacyCol {
    /* background-color: #CCC; */
    font-size: var(--fs-650);
    p, h1, h2 {
      padding-left: 2%;
      padding-right: 2%;
      font-size: var(--fs-650);
      line-height: 1.8em;
    }
  }

  .privacyCol > h1 {
    font-size: var(--fs-700);
    text-align: center;    
  }

  .privacyCol > ul {
    padding-left: 5%;
    padding-right: 5%;
  }
  .privacyCol > ul > li {
    list-style-type: disc;
    font-size: var(--fs-625);
    margin-bottom: 6px;
  }
  /* END Privacy Policy */
  
  /* begin subscription class adjustments for mobile */
  .subscription-container {

    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
  
  .contactInputEmail[type="email"] {
    width: 100%;
    border: 1px solid #ccc;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
    margin-top: 12px;
    border-radius: 6px;
  }
  
  .contactInputEmail[type="email"],
  .contactButton[type="button"] {
    font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
  }
  
  input.contactInputEmail:focus {
    outline: none;
    border: 2px solid #007BFF; /* Blue border */
    background-color: #E6F4FF; /* Light blue background */
  }
  
  .contactInputEmail[type="email"]:hover {
    -webkit-transition: border-color 0.3s ease-in-out;
    -moz-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    border: 1px solid #aaa;
  }

  /* END subscription class adjustments for mobile */
  
  /* .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  } */

  .btn {
    width: 100%;
  }

  /* .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .footer-container{
    padding: 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .footer-link-items{
    align-items: center;
    width:380px;
    padding-left: 0%;
  }

  .social-icon-link{
    margin: 0px 20px 0px 20px;
  }

  .social-link-items{
    margin:auto;
    padding-left:0%;
  }

  .social-logo{
    justify-content: center;
  }

  .social-icons{
    justify-content: center;
  } */

}

@media screen and (max-width: 768px) {
  .privacyContainer {
    /* background-color: #CC00CC; */
  }

  /* Privacy Policy */
  .privacyCol {
    /* background-color: #CCC; */
    font-size: var(--fs-650);
    p, h1, h2 {
      padding-left: 0%;
      padding-right: 0%;
      font-size: var(--fs-650);
      line-height: 1.8em;
    }
  }

  .privacyCol > h1 {
    font-size: var(--fs-700);
    text-align: center;    
  }

  .privacyCol > ul {
    padding-left: 5%;
    padding-right: 5%;
  }
  .privacyCol > ul > li {
    list-style-type: disc;
    font-size: var(--fs-625);
    margin-bottom: 6px;
  }
  .privacyTable {
    margin: 0px;
    font-size: var(--fs-600);
    line-height: 1.8em;
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .privacyContainer th, td {
    vertical-align: top;
  }
  /* END Privacy Policy */

  /* subscription section */
  .subscription-container {
    flex-direction: column; /* Stack the children vertically */
    align-items: center; /* Center-align the children horizontally */
  }

  .bottomLeft {
    width: 80%; /* Adjust the width as needed for smaller screens */
    margin-left: 0; /* Reset any negative margin that was used for larger screens */
    margin-bottom: 20px; /* Add some space below the image, adjust as needed */
    object-fit: scale-down; /* Adjust to ensure the image fits well */
  }

  .content-wrapper {
    width: 100%; /* Utilize the full width of the container */
  }
  
  /* END subscription section */
}
/* END from /src/components/CSS/Footer.css */

/* Spinner for Teams.js maybe one day make global for all components */
/* Spinner works but not within the component but in the middle of browser window */
/* THIS IS NOT BEING USED IN ANYTHING FOR NOW */
/* Loader/Spinner CSS */

/* .my-component-container {
  position: relative; 
} */

.my-component-container {
  position: relative;
  width: 100%; /* or a fixed width, e.g., 300px */
  height: 100px; /* Adjust as needed */
  /* Add a background color to ensure it's visible */
  /* background-color: #f0f0f0; */
}


.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 80px;
  border-radius: 50%;
  border-left-color: #09f;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: spin 1s ease infinite;
}


/* .spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #09f;

  animation: spin 1s ease infinite;
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* END Spinner code */