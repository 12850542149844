.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2.5rem;
  
    & span {
      min-height: 42px;
      text-transform: uppercase;
      margin-bottom: 1.0rem;
      font-size: 1.4rem;
      font-weight: 600;
      color: var(--color-primary-purple-variant);
      letter-spacing: 1px;
    }
    & h3 {
      font-size: 1.2rem;
      margin-bottom: 3rem;
      white-space: nowrap;
      overflow-x: hidden;
      overflow-y: hidden;
      text-overflow: ellipsis;
      max-width: -webkit-fill-available;
    }
  }

  .article{
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25) !important;
    border-radius: 6px;
    overflow: hidden;
  }
  
  .footer {
    display: flex;
    justify-content: center;
    text-align: left;
    align-items: center;
    margin-top: 4rem !important;
  
    & span {
      font-size: 1.8rem;
      font-weight: 400;
      color: #fff;
      margin-right: 10rem;
  
      & b {
        font-weight: 600;
        margin-right: 5px;
      }
    }
  }
  
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      text-align: center;
  
      & span {
        margin-right: 0;
        margin-bottom: 3rem;
      }
    }
  }
  
  @media (max-width: 576px) {
    .footer {
      margin-top: 1rem !important;
  
      & span {
        margin-bottom: 2rem;
      }
    }
  }
  