.wrapper {
    display: flex;
    align-items: center;
  }
  
  .contact_info {
    color: #fff;
    padding-left: 4rem;
  
    & h2 {
      color: inherit;
      font-size: 4rem;
      font-weight: 200;
      margin-bottom: 3rem;
      line-height: 1.2;
    }
  
    & p {
      color: inherit;
      margin-bottom: 4rem;
    }
  }
  
  @media (max-width: 768px) {
    .contact_info {
      padding-left: 0;
      margin-top: 3rem;
    }
  }
  
  @media (max-width: 576px) {
    .contact_info {
      margin-top: 0;
  
      & h2 {
        margin-bottom: 2rem;
        line-height: 1;
      }
  
      & p {
        margin-bottom: 2rem;
      }
    }
  }
  