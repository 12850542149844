* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  *:focus {
    outline: none;
  }
  
:root {
  --color-yellow-gold-dark: #ffaa2a;
  --color-yellow-gold: #ffc731;
  --color-bg: #1b1f2f;
  --color-bg-variant: #171859;
  --color-primary: #EE22E7;
  --color-primary-purple-variant: #8705EF;
  --color-dark: #131082;
--color-primary-blue-variant: #0591d7;
  /* --color-primary-blue-variant: rgba(77, 181, 255, 0.4); */
  --color-white: #ffffff;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-blueLikeTealIsh: #219EBC; /*  from theme - blueLikeTealIsh and I like it but went with a bit darker one */ 
  --color-greyBG: #EEEFF1 /* from theme - greyBG */
}

#root{
  /* padding-top: 100px; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

.indexToc {
  background-color:#CCCCCC;
}

  html {
    font-size: 10px;
    /* background: #fff; */
    overflow-x: hidden;
  }
  
  body {
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 2rem;
  }
  
  li {
    list-style: none;
  }
  
  a {
    text-decoration: none;
  }
  
  h2 {
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 2.5rem;
    color: inherit;
  }
  
  h3 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.4;
    margin-bottom: 1.5rem;
    letter-spacing: 1px;
    color: inherit;
  }
  
  h4 {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 3rem;
    color: inherit;
  }
  
  .subtitle {
    display: inline-block;
    text-transform: uppercase;
    /* color: var(--color-blueLikeTealIsh); */
    color:#fff;
    /* font-size: 1.2rem; */
    font-size: 4rem;
    font-weight: 600;
    padding-bottom: 16px;
    letter-spacing: 1px;
  }

  .layout-header {
    /* background-color: #FF0000; */
    color:#fff;
    margin-top: 0px;
    flex-basis: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  
    & p {
      max-width: 600px;
    }
  
    h2 {
      max-width: 500px;
    }
  }
  
  .layout-team-header {
    /* background-color: #FF0000; */
    color:#fff;
    margin-top: 0px;
    flex-basis: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  
    /* & p {
      max-width: 600px;
    }
  
    h2 {
      max-width: 500px;
    } */
  }

  .layout-team-header > p {
    max-width: 600px;
  }

  .layout-team-header > p {
    max-width: 500px;
  }
  
  .layout-footer {
    flex-basis: 100% !important;
    /* position:absolute;
    bottom:27px;
    right:0;
    left:0; */
    color:#fff;
  }

  .layout-footer > span{
    font-size:2.5rem;
  }
  
  input,
  textarea {
    border-radius: 0;
  }
  

  
  @media (max-width: 576px) {
    .layout-header {
      & .subtitle {
        margin-bottom: 15px;
      }
  
      & .title {
        margin-bottom: 20px;
      }
  
      & .parargraph {
        margin-bottom: 5px;
      }
    }
  }