.parallax {
    position: relative;
    background: #ccc;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(rgb(40, 40, 50), 0.8);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
.intro {
  min-height: 30px;
  /* color: #fff; */
  /* background: rgba(0, 0, 0, 0.4); */
  color: var(--color-dark);

  /* background: url('../../../assets/images/dude-with-left-arm-extended-left.png') center right/contain no-repeat; */
  padding: 4rem 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* transform: translateY(-15rem); */
  /* max-width: 840px; */
  margin: 0 auto;

  & p {
    /* MOST ALL TEXt */
    /* for prod */
    color: var(--color-primary-purple-variant);
    letter-spacing: 1px;
    word-spacing: 2px;
    font-weight: 300;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  & h1 {
    font-size: 6rem;
    font-weight: 200;
    line-height: 1.2;
    margin-bottom: 1.5rem;

    & b {
      font-weight: 400;
    }
  }

  & a {
    transform: translateY(50%);
    align-self: flex-end;
  }
}

.headerBackgroundLeftSide{
  padding: 0;
  /* background:black; */
  /* url('../../../assets/images/bc_ai/3_stack_158_158.png') */
  /* url('../../../assets/images/bc_ai/7_stack_128_128.png') */
  /* url('../../../assets/images/bc_ai/bc_3_stack_128.png') */
  /* url('../../../assets/images/bc_ai/lady-holding-a-laptop-shorter_350_700.png') */
  /* 2_then_10_stack_158.png */
  /* one_stack5_high_158.png */
  /* following are good but not perfect */
  /* url('../../../assets/images/bc_ai/7_stack_128_128.png') bottom 35px right 62px/contain no-repeat, */
  /* url('../../../assets/images/bc_ai/lady-holding-a-laptop-shorter_350_700.png') top -10px right -60px/contain no-repeat; */
  background: url('../../../assets/images/bc_ai/dude-with-left-arm-extended-left-skewed_350_700.png') top -40px left -30px/contain no-repeat,
              url('../../../assets/images/bc_ai/3_stack_158_158.png') bottom -55px left 30px/contain no-repeat,
              url('../../../assets/images/bc_ai/2_then_10_stack_158.png') bottom 35px right 64px/contain no-repeat,
              url('../../../assets/images/bc_ai/one_stack5_high_158.png') bottom 100px right -10px/contain no-repeat,
              url('../../../assets/images/bc_ai/lady-holding-a-laptop-shorter_350_700.png') top -16px right -60px/contain no-repeat;
  background-size: auto, 20%, 18%, auto;
  /* margin-top: -100px; */
  & .inner{
    width: 100vw;
    max-width: 100vw;
  }
}

.container {
display: flex;
flex-direction: column;
min-width: 100%;
/*  justify-content: center;
align-items: center; */
/* height: 100vh;  */
/* This sets the container to take the full viewport height */
}

.content {
  text-align: center;
}

.header {
  color: var(--color-primary-purple-variant);
  font-size:xxx-large;
  text-align: center;
  align-content: center;
    height: 100px;
    /* background-color: lightgray; */
    background-color: transparent;
}

.shadowText {
  text-shadow: 3px 2px 5px rgba(0, 0, 0, 0.3);
}

.middle {
  
  /* border-radius:15px; */
  /* box-shadow: 6px 8px 12px rgba(0, 0, 0, 0.2); */
  /* height: 400px; */
  /* flex-grow: 1;  This makes the middle div take up the remaining space */
  /* background-color: var(--color-yellow-gold); */
  /* background-color: transparent; */
  display: flex;        /* Aligns children (cards) in a row */
  justify-content: space-around; /* Distributes space around the items */
  /* align-items: flex-start; Aligns items at the start of the container vertically */
  align-items:center; /* Aligns items at the start of the container vertically */
  /* flex-wrap: wrap;       Allows wrapping to the next line if the container is too small */
}

.footer {
  color: var(--color-primary-purple-variant);
  font-size:xx-large;
  text-align: center;
  align-content: center;
  height: 100px;
  /* background-color: lightgray; */
  background-color: transparent;
}

.card-img-top {
    width: 50% !important;
}

.form {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
  
@media (max-width: 768px) {
  .parallax {
      position: relative;
      /* background: #ccc; */
      background: transparent;
      height: auto !important;
      display: flex;
      justify-content: center;
      align-items: center;
      /* box-shadow: inset 0 0 0 1000px rgba(rgb(40, 40, 50), 0.8); */
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

  .intro {
    background: transparent;
    /* background: rgba(22, 255, 0, 0.4); */
    padding: 2rem 2rem 0;
    /* align-items:center; */
    text-align: left;

    & h1 {
      font-size: 5rem;
    }
  }

  .container{
    display: flex;
    flex-direction: column;
    min-width: 100%;
    /* height: 100vh; */
  }

  .header {
    height: 100px !important;
    /* following is for prod */
    background-color: transparent;
    /* following is for dev */
    /* background-color: #00ff00; */
  }

  .middle {
    flex-direction: column;
    /* align-items: stretch; */
    flex-grow: 1;  
  }

  .footer {
    height: 50px;
    /* background-color: #4444FF; */
  }
}
  
@media (max-width: 576px) {
  .parallax {
      height: auto !important;
      position: relative;
      background: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: inset 0 0 0 1000px rgba(rgb(40, 40, 50), 0.8);
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

  .intro {
    margin-top: 150px;
    /* background: rgba(22, 0, 255, .7); */
    padding: 1rem 1rem 0 1rem;
    align-items: center;
    text-align: left;
    transform: translateY(-10rem);

    & h1 {
      font-size: 3rem;
    }

    & a {
      align-self: center;
      margin-top: -2rem;
    }
  }
}
  
@media (max-width: 376px) {
  .intro {
    min-height: 15px !important;
    /* margin-top: 20px; */
    /* for production */
    background: transparent;
    /* for development */
    /* background: rgba(255, 0, 255, 0.4); */
    padding: 2rem 1rem 0 1rem;
    align-items: center;
    text-align: left;
    transform: translateY(-10rem);

    & h1 {
      font-size: 3rem;
    }

    & a {
      align-self: center;
      margin-top: -2rem;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    /* padding-top: 400px; */
    /* height: 100vh;  */
    /* This sets the container to take the full viewport height */
    }
    
    .header {
        height: 50px;
        /* for prod */
        background-color: transparent;
        /* for dev */
        /* background-color: lightgray; */
    }
    
    .middle {
        /* height: 300px; */
        flex-grow: 1; /* This makes the middle div take up the remaining space */
        /* for prod */
        /* background-color: transparent; */
        
        /* for dev */
        background-color: lightblue;
    }
    
    .footer {
        height: 50px;
        /* for prod */
        background-color: transparent;
        /* for dev */
        /* background-color: lightgray; */
    }
}

/* Add media query for mobile view */
@media (max-width: 400px) {
  .middle {
      flex-direction: column; /* Stack items vertically */
      align-items: stretch;   /* Ensure items take full width */
  }
}
