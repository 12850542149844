.section {
    width: calc(100% - 3rem);
    margin-bottom: 10rem;
    overflow: hidden;
  }
  
  .client {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    & img {
      opacity: .2;
      width: 70%;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 576px) {
    .section {
      margin-bottom: 7rem;
    }
  }