.card {
    box-shadow: 0 0.2rem 0.8rem rgba(40, 40, 50, 0.1);
    background: #fff;
    padding: 9rem 3rem 5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s ease;
  
    &:hover {
      box-shadow: 0 0.2rem 0.8rem rgba(40, 40, 50, 0);
      background: transparent;
    }
  
    & figure {
      position: relative;
      width: 7rem;
      margin-bottom: 2rem;
      transform: translateX(1rem);
  
      & span {
        position: absolute;
        width: 9rem;
        height: 9rem;
        top: -4rem;
        left: -3rem;
        border-radius: 50%;
        background: rgb(240, 240, 240);
        z-index: 1;
        opacity: 0.3;
      }
  
      & img {
        position: relative;
        z-index: 2;
        width: 7rem;
      }
    }
  
    & p {
      width: 100%;
      margin-top: 1rem;
    }
  
    & a {
      display: block;
      font-size: 1.3rem;
      font-weight: 500;
      margin-top: 2rem;
    }
  }
  
  .footer {
    display: flex;
    justify-content: center;
    margin-top: 3rem !important;
  
    & button {
      font-family: Poppins;
      cursor: pointer;
      border: none;
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: 600;
      transition: all 0.3s ease;
      background: none;
    }
  
    & i {
      margin-left: 5px;
    }
  }