.portfolio {
    display: flex;
    border-bottom: 2px solid;
    flex-wrap: wrap;
}

.wrapper {
    flex-basis: 33.333333%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wrapper:not(:last-of-type) .image {
    border-right: 2px solid;
}

.image {
    position: relative;
    height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &:hover .button {
        opacity: 1;
        transform: translateY(0);
    }

    & .button {
        opacity: 0;
        transform: translateY(-5rem);
        position: relative;
        transition: all .3s ease;
    }


    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(40, 40, 50, .8);
    }
}

.info {
    padding-top: 3.5rem;
    padding-bottom: 5rem;
    padding-right: 4rem;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    & span {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: .9rem;
    }

    & h3 {
        margin-top: 1rem;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 1.3;

        & a {
            color: #000;
        }
    }

    &>a {
        display: block;
        padding-top: 3rem;
        margin-top: auto;
        font-size: 1.4rem;
        font-weight: 600;
        color: #ccc;
        display: flex;
        align-items: center;
        transition: all .3s ease;

        &:hover {
            color: #000;
        }

        &:hover i {
            background: #000 !important;
        }
    }

    & i {
        font-size: 2.5rem;
        padding: 1rem;
        margin-left: 3rem;
        color: #fff;
        transition: all .3s ease;
    }
}

@media (max-width: 992px) {

    .portfolio {
        padding-bottom: 1.5rem;
    }

    .wrapper {
        flex-basis: 100%;
        flex-direction: row;
        margin: 1.5rem 0;
    }

    .wrapper:not(:last-of-type) .image {
        border-right: none;
    }

    .image {
        width: 30rem;
        height: 20rem;
        margin-right: 3rem;
    }

    .info {
        padding: 0;

        & a {
            margin-top: 0rem;
        }
    }

    /*-------------------------------------
            8. Packages 
    --------------------------------------*/
    .packages{
        background-color: aquamarine;
        padding:120px 0 90px;
        /* display: flex;
        flex-direction: column;
        flex-wrap: wrap; */
    }
    .packagesContent{
        margin-top:65px;
    }
    /*single-package-item */
    .singlePackageItem{
        border-radius: 5px;
        margin-bottom: 30px;
        padding-bottom: 20px;
        box-shadow: 0 0 20px rgba(0,0,0,.1);
        -webkit-transition: .5s; 
        -moz-transition:.5s; 
        -ms-transition:.5s; 
        -o-transition:.5s;
        transition: .5s;
        overflow: hidden;
    }
    .singlePackageItemTxt{
        padding: 0 25px;
    }
    .singlePackageItem img {
        width: 100%;
    }
    .singlePackageItem h3{
        position: relative;
        font-size: 20px;
        color: #565a5c;
        font-weight:500;
        font-family: 'Poppins', sans-serif;
        padding: 20px 0;
    }
    .singlePackageItem h3:after{
        position: absolute;
        content: " ";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #ebebeb;
    }
    .packagesPara{
        min-height: 80px;
        max-height: 80px;
        overflow: hidden;
        /* padding:17px 0 0; */
        text-transform: capitalize;
    }
    .packagesPara p{
        line-height: 1.50;
        font-size: 14px;
        color: #aaa;
        font-family: 'Poppins', sans-serif;
        margin-bottom:15px;
    }

    .packagesPara p span{
        display: inline-block;
        width: 150px;
    }

    .packagesReview {
        min-height: 40px;
        margin-top: 20px;
        padding-top:10px;
    }

    .packagesReview p i{

        color: '#CCCCCC';
        font-size: 16px;
    }
    .packagesReview span{
        /* background-color: #8938ae; */
        margin-left: 20px;
        font-size: 14px;
        color: #aaa;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
    }
    .aboutView.packages-btn{
        width: 110px;
        height: 35px;
        font-size: 14px;
        color: #fff;
        text-transform: capitalize;
        -webkit-transition: .5s; 
        -moz-transition:.5s; 
        -ms-transition:.5s; 
        -o-transition:.5s;
        transition: .5s;

    }
    .aboutView.packages-btn:hover{
        box-shadow: 0 5px 20px rgba(14,15,18,.3);
    }
    .singlePackage-item:hover{
        box-shadow: 0 0 20px rgba(0,0,0,.2);
    }
}

@media (max-width: 768px) {
    .image {
        width: 20rem;
    }

    /*-------------------------------------
        8. Packages 
--------------------------------------*/
.packages{
    background-color: rgb(127, 170, 255);
    padding:120px 0 90px;
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap; */
}
.packagesContent{
    margin-top:65px;
}
/*single-package-item */
.singlePackageItem{
    border-radius: 5px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    -webkit-transition: .5s; 
    -moz-transition:.5s; 
    -ms-transition:.5s; 
    -o-transition:.5s;
    transition: .5s;
    overflow: hidden;
}
.singlePackageItemTxt{
    padding: 0 25px;
}
.singlePackageItem img {
    width: 100%;
}
.singlePackageItem h3{
    position: relative;
    font-size: 20px;
    color: #565a5c;
    font-weight:500;
    font-family: 'Poppins', sans-serif;
    padding: 20px 0;
}
.singlePackageItem h3:after{
    position: absolute;
    content: " ";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ebebeb;
}
.packagesPara{
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
    /* padding:17px 0 0; */
    text-transform: capitalize;
}
.packagesPara p{
    line-height: 1.50;
    font-size: 14px;
    color: #aaa;
    font-family: 'Poppins', sans-serif;
    margin-bottom:15px;
}

.packagesPara p span{
    display: inline-block;
    width: 150px;
}

.packagesReview {
    min-height: 40px;
    margin-top: 20px;
    padding-top:10px;
}

.packagesReview p i{

    color: '#CCCCCC';
    font-size: 16px;
}
.packagesReview span{
    /* background-color: #8938ae; */
    margin-left: 20px;
    font-size: 14px;
    color: #aaa;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
}
.aboutView.packages-btn{
    width: 110px;
    height: 35px;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    -webkit-transition: .5s; 
    -moz-transition:.5s; 
    -ms-transition:.5s; 
    -o-transition:.5s;
    transition: .5s;

}
.aboutView.packages-btn:hover{
    box-shadow: 0 5px 20px rgba(14,15,18,.3);
}
.singlePackage-item:hover{
    box-shadow: 0 0 20px rgba(0,0,0,.2);
}
}

@media (max-width: 576px) {
    .portfolio {
        padding-bottom: 0;
    }

    .wrapper {
        background-color: rgb(13, 0, 255);
        flex-direction: column;
        margin: 0;
    }

    .image {
        width: 100%;
        height: 25rem;
    }

    .info {
        margin-top: 3rem;
        margin-bottom: 3rem;
        justify-content: center;
        text-align: center;
        align-items: stretch;

        & a {
            justify-content: space-between;
            padding-top: 3rem;
            margin-bottom: 3rem;
        }

    }

    .header p {
        margin-bottom: 0;
    }
}

/*-------------------------------------
        8. Packages 
--------------------------------------*/
.packages{
    padding:120px 0 90px;
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap; */
}
.packagesContent{
    margin-top:65px;
}
/*single-package-item */
.singlePackageItem{
    border-radius: 5px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    -webkit-transition: .5s; 
    -moz-transition:.5s; 
    -ms-transition:.5s; 
    -o-transition:.5s;
    transition: .5s;
    overflow: hidden;
}
.singlePackageItemTxt{
    padding: 0 25px;
}
.singlePackageItem img {
    width: 100%;
}
.singlePackageItem h3{
    position: relative;
    font-size: 20px;
    color: #565a5c;
    font-weight:500;
    font-family: 'Poppins', sans-serif;
    padding: 20px 0;
}
.singlePackageItem h3:after{
    position: absolute;
    content: " ";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ebebeb;
}
.packagesPara{
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
    /* padding:17px 0 0; */
    text-transform: capitalize;
}
.packagesPara p{
    line-height: 1.50;
    font-size: 14px;
    color: #aaa;
    font-family: 'Poppins', sans-serif;
    margin-bottom:15px;
}

.packagesPara p span{
    display: inline-block;
    width: 150px;
}

.packagesReview {
    min-height: 40px;
    margin-top: 20px;
    padding-top:10px;
}

.packagesReview p i{

    color: '#CCCCCC';
    font-size: 16px;
}
.packagesReview span{
    /* background-color: #8938ae; */
    margin-left: 20px;
    font-size: 14px;
    color: #aaa;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
}
.aboutView.packages-btn{
    width: 110px;
    height: 35px;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    -webkit-transition: .5s; 
    -moz-transition:.5s; 
    -ms-transition:.5s; 
    -o-transition:.5s;
    transition: .5s;

}
.aboutView.packages-btn:hover{
    box-shadow: 0 5px 20px rgba(14,15,18,.3);
}
.singlePackage-item:hover{
    box-shadow: 0 0 20px rgba(0,0,0,.2);
}
/*single-package-item */