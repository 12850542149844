.register {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .register__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
  }
  
  .register__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .register__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
  }
  
  .register__google {
    background-color: #4285f4;
  }
  
  .register div {
    margin-top: 7px;
  }
  .wrappert {
    margin: auto;
    width: 50%;
    /* border: 3px solid green; */
    padding: 10px;
    text-align: center;
    }
    
  .center {
      margin: auto;
      width: 50%;
      border: 3px solid green;
      padding: 10px;
      text-align: center;
    }

    .inputEmail {
      margin-bottom: 1em;
      padding: 6px;
    }
    
    .inputPassword {
    
      padding: 6px;
    }
    
    .btnSubmit{

      margin: 1.8em;
    }