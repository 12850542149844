.wrapper {
    position: relative;
    
  }

    /* Styles for the outer div */
  .outerDiv {
    width: 100%; /* Full width of the browser */
    background-color: lightgray; /* Just for demonstration */
  }

  /* Styles for the inner div */
  .innerDiv {
    width: 50%; /* Half the width of the outer div */
    margin: 0 auto; /* Center the div */
    background-color: skyblue; /* Just for demonstration */
    text-align: center; /* Optional: for centering content inside the inner div */
  }
  
  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .status {
    text-align: center;
    margin: 2rem 0;
    font-weight: 500;
    color: rgb(92, 228, 114);
    font-size: 1.2rem;
  }
  
  .status.error {
    color: red;
  }
  
  .contact_form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    & h4 {
      font-size: 2rem;
      font-weight: 400;
      margin-top: 1rem;
      margin-bottom: 4rem;
      letter-spacing: 1px;
      line-height: 1.3;
    }
  
    & input,
    & textarea {
      padding: 2rem 1.6rem;
      background-color: rgb(245, 245, 245);
      border: none;
      margin-bottom: 2rem;
      transition: all 0.3s ease;
  
      &::placeholder {
        font-size: 1.2rem;
        font-family: "Poppins", sans-serif;
        transition: all 0.3s ease;
      }
  
      &:focus {
        outline: none;
        background-color: rgb(235, 235, 235);
      }
  
      &:focus::placeholder {
        opacity: 0.5;
      }
    }
  
    & .checkbox_group {
      display: block;
      position: relative;
      padding-left: 4.5rem;
      /* cursor: pointer; */
      font-weight: 600;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-bottom: 3rem;
  
      &:hover input ~ .checkmark {
        background-color: rgb(235, 235, 235);
      }
  
      & p {
        font-size: 1.2rem;
        transform: translateY(4px);
        line-height: 1.4;
      }
  
      & input {
        position: absolute;
        opacity: 0;
        /* cursor: pointer; */
        height: 0;
        width: 0;
  
        &:checked ~ .checkmark {
          background-color: rgb(235, 235, 235);
  
          &:after {
            display: block;
          }
        }
      }
  
      & .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 2.5rem;
        width: 2.5rem;
        background-color: rgb(245, 245, 245);
        transition: all 0.3s ease;
  
        &:after {
          content: "";
          position: absolute;
          display: none;
  
          left: 9px;
          top: 5px;
          width: 5px;
          height: 1rem;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
  
  @media (max-width: 576px) {
    .contact_form {
      & h4 {
        margin-top: 0;
        margin-bottom: 2rem;
      }
  
      & .checkbox_group {
        margin-bottom: 1rem;
      }
    }
  }

  /* @import url(https://fonts.googleapis.com/css?family=Roboto:400,300,600,400italic);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
} */

/* body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 12px;
  line-height: 30px;
  color: #777;
  background: #4CAF50;
} */

.container {
  /* border: #43A047 solid 1px; */
  max-width: 400px;
  margin-bottom: 30px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2000;
}

.contactInputText[type="text"],
.contactInputEmail[type="email"],
.contactInputTel[type="tel"],
.contactInputUrl[type="url"],
.contactTextarea,
.contactButton[type="button"] {
  font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
}

.contact {
  background: #F9F9F9;
  padding: 25px;
  margin: 0 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

/* for alert and validation sections */
.InitialDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FB8500;
  min-height: 48px;
  /* height: '40px', */
  margin-bottom: 30px;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 2px 2px 5px  #EBDEDF;
  text-align: center;  
}

.InnerDisplay {
  text-align: center;
  color: #FFF;
  font-size: 1.6rem;
  line-height: 1.0rem;
  font-weight: 500;
}

.ErrorDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FAEFEF;
  min-height: 48px;
  /* height: 40px; */
  margin-bottom: 30px;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 2px 2px 5px  #EBDEDF;
  text-align: center;
}

.InnerDisplayError {
  text-align: center;
  color: #FF0000;
  font-size: 1.6rem;
  line-height: 1.0rem;
  font-weight: 500;
}

.ErrorDiv p {
  font-size: 1.6rem;
  margin: 0 0;
  padding: 2px 0;
  line-height: 1.5rem;
}

/* END alert and validation sections */

.centerButton {
  display: block;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}


.contact input.contactInputEmail:focus, 
.contact input.contactInputText:focus,
.contact input.contactInputTel:focus {
  outline: none;
  border: 2px solid #007BFF; /* Blue border */
  background-color: #E6F4FF; /* Light blue background */
}

.contactInputSelect {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  background: #FFF;
  margin-bottom: 5px;
}

.contactInputSelect:focus {
  border: 1px solid #aaa;
}

.contactInputSelect:focus {
  outline: none;
  border: 2px solid #007BFF; /* Example: Blue border for focus */
  background-color: #E6F4FF; /* Example: Light blue background for focus */
  box-shadow: 0 0 3px 2px #007BFF; /* Example: Adding a glow effect for focus */
}


.contactButton:focus {
  outline: none;
  background-color: #0056B3; /* Example: Darker blue background */
  color: white;
  box-shadow: 0 0 3px 2px #007BFF; /* Example: Adding a glow effect */
}

.contactH3 {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}

.contactH4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

.custFieldset {
  border: medium none !important;
  margin: 0 0 8px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

.contactInputText[type="text"],
.contactInputEmail[type="email"],
.contactInputTel[type="tel"],
.contactInputUrl[type="url"],
.contactTextarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #FFF;
  margin: 0 0 5px;
  padding: 10px;
}

.contactInputText[type="text"]:hover,
.contactInputEmail[type="email"]:hover,
.contactInputTel[type="tel"]:hover,
.contactInputUrl[type="url"]:hover,
.contactTextarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

.contactTextarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

.contactButton[type="button"] {
  /* cursor: pointer; */
  width: 100%;
  border: none;
  background: #4CAF50;
  color: #FFF;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}

.contactButton[type="button"]:hover {
  background: #43A047;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.contactButton[type="button"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.copyright {
  text-align: center;
}

.contactInput:focus,
.contactTextarea:focus {
  outline: 0;
  border: 1px solid #aaa;
}

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}