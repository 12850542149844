.footer-container-main{
  box-shadow: 1px 1px 10px rgb(0, 0, 0, 1);
  /* z-index:5000; */
  margin-top:100px;

  &.homepage{
    height: 325px !important;
    /* margin-top:-100px; */
  }
}

.footer-container {
    /*background-color: #242424;*/
    /* background: linear-gradient(90deg, rgb(223, 103, 4) 0%, rgb(192, 119, 2) 100%); */
    /* height: 325px; */
/*
    background: linear-gradient(90deg, rgb(5, 145, 215) 0%, rgba(7, 131, 194, 0.838) 100%) !important;
    background-size: cover;
    */    
    
    background-color: rgb(5, 145, 215);
    background-image: url('../../../assets/images/bg/texture_7.png');


    /* For Safari */
    /* background-image: url('./assets/images/bg/texture_7.png'); */
    /* padding: 4rem 0 2rem 0; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-size: 1000px;
    background-position: center;
    background-repeat: repeat;
    width: 100%;

    &.homepage{
      height: 325px !important;
      flex-direction: column !important;
    }
  }

  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    font-size: var(--fs-650);
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    line-height: 1.8;
    padding-left: 5%;
  }
  
  .social-link-items {
    flex-direction: revert;
    padding-left: 5px;
    align-self: center;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
    /* color: #000; */
  }
  
  .footer-link-items a {
    color: #fff;
    /* color: #000; */
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: rgb(135, 5, 239);
    /* color: #e9e9e9; */
    opacity: var(--op-05);
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    /* color: #000; */
    font-size: 36px;
    margin:0 5px 0 5px;
  }
  
  .social-icon-link:hover{
  color: #f6ff00;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    /* color: #000; */
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    max-width: 300px;
  }

  @media (min-width: 993px){
  
    .footer-container {
      height: auto;
    }
  
  }

  @media screen and (max-width: 820px) {
   
    .footer-container {
        flex-direction: column;

      &.homepage{
          height: auto !important;
      }
    }
    
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }

    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  
    .footer-container{
      padding: 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
  
    .footer-link-items{
      align-items: center;
      width:380px;
      padding-left: 0%;
    }
  
    .social-icon-link{
      margin: 0px 20px 0px 20px;
    }
  
    .social-link-items{
      margin:auto;
      padding-left:0%;
    }
  
    .social-logo{
      justify-content: center;
    }
  
    .social-icons{
      justify-content: center;
    }
  
  }