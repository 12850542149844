.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    width: 500px;  /* or whatever width you want */
    height: 300px; /* fixed height for the modal */
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow-y: auto; /* to enable scrolling */
}

.modalContent p {
    margin: 0 0 1rem; /* Adjust as needed */
}

/* Optional: You can style the close button if you wish */
/* .modalContent button {
    position: absolute;
    top: 10px;
    right: 10px;
} */

.modalFooter {
    display: flex;
    justify-content: flex-end;
}

.modalContent button {
    margin: 0;  /* Reset any margin */
}