.wrapper {
  display: flex;

  &.show {
    display: block;
  }
}

.modal {
  position: relative;
  /* width: 70%; */
  max-width: 50rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 40001;

  &>i {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: #000;
    font-size: 2rem;
    z-index: 1000;
    cursor: pointer;
  }
}

.modalmax{
  max-width: -webkit-fill-available !important;
  padding: 35px;
  
  width:50rem;
}

span > p {
  display:flex;
  font-size: 2rem;
  justify-content: center;
  /* text-align: center; */
  text-transform: uppercase;
}

figure {
  width: auto;
  height: 6px;
  bottom: 0;
  right: 0;
  display: block;
  transition: all ease 0.5s;
  font-weight: 500;
}

.container{
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0 15px 0;

  .thumbnail {
    flex-grow: 1;
    width: 50%;
    text-align: center;
  }
  
  .thumbnail img {
    max-width: -webkit-fill-available;
    height: auto;
    max-height: 175px;
    border-radius: 14px;
  }

  .details {
    flex-grow: 1;
    width: 50%;
    font-size:2rem;
    padding-left: 20px;

    & .detailRow{
      display: flex;
      align-items: center;

      & .detailKey{
        flex-grow: 1;
        padding-right: 5px;
        text-align: right;
      }

      & .detailValue{
        flex-grow: 1;
        text-align: center;
        max-width: 80px;
      }
    }
  }

  a {
    padding: 0.5rem 2.9rem !important;
  }

  figcaption{
    /* padding-left: 10px; */
  }

  p{
    margin-bottom: 0;
    font-size:1.2rem;
  }
}

.text{
  text-align: left;
  max-width: 350px;
  padding-left:20px;
  p {
    font-size: 1.3rem;
  }
}

.btn{
  text-align: -webkit-center;

  a {
    max-width: 200px;
  }
}



@media (max-width: 576px) {
  .modal {
    width: 90%;
  }
}

@media (max-width: 480px) {
  /* .modal {
    width: 90%; */

    .container {
      .details {
        width:100%;
        padding-top: 14px;
          & .detailRow{
      
          & .detailKey{
            flex-grow: 0;
            width: 50%;        
          }

          & .detailValue{        
            text-align: left;
            width: 50%;
          }
        }
      }
    }
  /* } */
}
